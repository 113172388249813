import React from 'react';
import './ContactUs.css';
import { AiFillFacebook, AiFillInstagram, FaBeer } from "react-icons/ai";
import {FbLink,InstaLink} from "../Home/data";

class ContactUs extends React.Component {

    constructor(props) {
        super(props);
        // this.count = 0;
        this.state = {
            query: "",
            contact: "",
            queryResponse: "",
            queryResponseVisibility: true,
        };
        // This binding is necessary to make `this` work in the callback
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOnChangeQuery = this.handleOnChangeQuery.bind(this);
        this.handleOnChangeContact = this.handleOnChangeContact.bind(this);
    }

    handleOnChangeQuery = (event) => {
        this.setState({
            query: event.target.value,
        })
    };

    handleOnChangeContact = (event) => {
        this.setState({
            contact: event.target.value,
        })
    };
    handleSubmit() {
        console.log("inside handleSubmit");
        this.setState({
            queryResponseVisibility: true,
            queryResponse: this.state.query + " contact: "+this.state.contact,
        })
    };

    render() {
        let queryResponse = this.state.queryResponse;
        let queryResponseVisibility = this.state.queryResponseVisibility;
        let query = this.state.query;

        return <div className="Background">
            <header className="ContactUsTitle">
                Contact Us
            </header>

            <div className="queryTitle" > 
            Please write your query here:
                <label>
                    <textarea onChange={this.handleOnChangeQuery} name="query" rows={5} cols={40} className="queryTextbox" />
                </label>
            </div>

            <div className="queryTitle" >
                Your contact details(email/mobile):
                <label>
                    <textarea onChange={this.handleOnChangeContact} name="query" rows={1} cols={40} className="queryTextbox" />
                </label>
            </div>
            <div className="queryTitle">
                <button onClick={this.handleSubmit} className="submitButton">Submit</button>
            </div>

            <div className="queryTitle" >
                {queryResponseVisibility ? queryResponse : ""}
            </div>
            
            <header className="ContactUsTitle">
                OR
            </header>
            <header className="connectWithUs">
                Connect with Us
            </header>
            <p className="iconsMargins">
                <a href={FbLink}>
                    <span className="iconsMargins"><AiFillFacebook size={50} color="rgb(192,232,234)" /> </span>
                </a>
                <a href={InstaLink}>
                    <span className="iconsMargins"><AiFillInstagram size={50} color="rgb(192,232,234)" /> </span>
                </a>
            </p>
            
        </div>;
    }
}

export default ContactUs

// onChange = {(e) => {
//     setName(e.target.value);
// }}


// iuefnviouwenbvpiowrjmtvokewrnmtvjrtemeirosuv wjehbniowjenrwdejkflnv defnsjkv

// textarea name = "postContent" rows = { 4} cols = { 40}kwjerbv jekhwrb vkjwrb cvadwvckejnrwbklvjewbrnlvkjbenrwkvjbqwkljbrcqjhewrbcjkvalsbd vcmn, ads fcvjkhwebrfjklvwebrvlkjebrfjklvbwe rjkhvbwelkjrbvklwjervlwebr jklvewbrjklvb askfv wkejrlbnvkjewlrbvjklerb cvkjwerbnv