
import './App.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Home from './Home/Home';
import ContactUs from './ContactUs/ContactUs';
import AboutUs from './AboutUs/AboutUs';
import * as React from 'react';

function App() {

  return (
      <Tabs
        defaultActiveKey="home"
        id="uncontrolled-tab-example"
      fill
      >
        <Tab eventKey="home" title="Home">
          <Home/>
        </Tab>
      <Tab eventKey="about" title="About us">
        <AboutUs />
      </Tab>
        <Tab eventKey="contact" title="Contact us">
        <ContactUs />
        </Tab>
      
      </Tabs>
      
  );
}

export default App;




{/* <Tabs
  id="controlled-tab-example"
  className="App-home-tab"
  defaultActiveKey="home"
  fill
  style={{
    width: '100%',
    paddingLeft: '5px',
    paddingRight: '5px',
  }}
>
  <Tab
    eventKey="home"
    title="Home"
    style={{
      width: '10  0%',
      marginLeft: '10px',
      marginRight: '10px',
      marginTop: '10px'
    }}
  >
    <Home />
  </Tab>
  <Tab
    eventKey="home2"
    title="Home2"
    style={{
      width: '100%',
      marginLeft: '10px',
      marginRight: '10px',
      marginTop: '10px'
    }}
  >

  </Tab>
</Tabs> */}