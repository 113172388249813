import React from 'react';
import './AboutUs.css';
import { AboutUsText, SaurabhImages } from '../Home/data';


class AboutUs extends React.Component {
    render() {
        return <div className="Background">
            <header className="AboutUsTitle">
                About Us
            </header>

            <p className="HomePagePrograms">
                Saurabh Sangvekar
            </p>

            <div className="aboutUsText">
                {AboutUsText}
            </div>
            < div className="imagesContainer" >
            {
                    SaurabhImages.map((item) => (
                    <div className="imagesItem">
                        <img src={item.img} alt='/' />
                    </div>
                ))
            }
            </div >
        </div>;
    }
}

export default AboutUs