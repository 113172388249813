
export const FbLink = "https://facebook.com"
export const InstaLink = "https://instagram.com"
export const AboutUsText = "Our coach Saurabh Sangvekar himself is a world class swimmer with numerous accolades to his name. "+
"He has won several gold medals at international as well as national level. "+
"With plenty of swimming experience, he started coaching youth and has already "+
"trained and helped many to get medals at national level"
export const SaurabhImages = [
    {
        id:1,
        img:"saurabh_1.jpeg"
    },
    {
        id: 2,
        img: "saurabh_2.jpeg"
    },
    {
        id: 3,
        img: "saurabh_3.jpeg"
    }
]
export const servicesData = [
    {
        id:1,
        img: "child_swimming.png",
        title: "Beginner",
        text: 'We teach the basics of swimming to kids and make sure they have a strong foundation. We cover all the varieties like breathstroke, freestyle, backstroke etc.',
        days: "3 days a week program",
        duration: "Duration : 3 months"
    },
    {
        id:2,
        img: "adult_swimming.png",
        title: "Intermediate",
        text: "Once the foundation of swimming is established, we proceed towards establishing routines to make swimming a lifestyle and not just a fun activity. We inculcate the right attitude necessary to become a top class swimmer.",
        days: "3 days a week program",
        duration: "Duration : 3 months"
    },
    {
        id: 3,
        img: "swimming_top.png",
        title: "Advanced",
        text: "We train the professional swimmers with personal attention to the details which gives you that extra edge to win the competitions at world level",
        days: "3 days a week program",
        duration: "Duration : 3 months"
    },
]