import React from 'react';
import logo from '../logo.svg';
import './Home.css';
import '../Common/Common.css'
import { servicesData } from './data'

class Home extends React.Component {

    constructor(props) {
        super(props);
        // this.count = 0;
        this.state = {
            idx: 0,
            items: servicesData,
            len: servicesData.length,
        };
        // This binding is necessary to make `this` work in the callback
        this.handleClickLeft = this.handleClickLeft.bind(this);
        this.handleClickRight = this.handleClickRight.bind(this);
    }
    handleClickRight() {
        this.setState({
            idx : (this.state.idx + 1) % this.state.len,
        });
    }

    handleClickLeft() {
        let nextIdx = 0;
        if (this.state.idx == 0){
            nextIdx = this.state.len-1;
        }else{
            nextIdx = this.state.idx - 1;
        }
        this.setState({
            idx: nextIdx,
        });
    }
    render() {
        let items = this.state.items;
        let i = this.state.idx;
        return <div className="Background">
            <header className="HomePageTitle">
                SharkFin Aquatics  
            </header>
            <p className="HomePageSlogan">
                Swim through the ocean of dreams!
            </p>

            <p className="HomePagePrograms">
                Our Programs
            </p>

            <div className="servicesContainer">
            {
                <div className="servicesItem">
                        <p className="HomePagePrograms">
                            <span onClick={this.handleClickLeft}>{"<"}</span>&nbsp;&nbsp;&nbsp;&nbsp;{items[i].title}&nbsp;&nbsp;&nbsp;&nbsp;<span onClick={this.handleClickRight}>{">"}</span>
                        </p>
                    <div className="servicesItemText">
                        {items[i].text}
                    </div>
                    <div className="servicesItemTextItalic">
                        {items[i].days}
                    </div>
                    <div className="servicesItemTextItalic">
                        {items[i].duration}
                    </div>
                    <img src={items[i].img} alt='/'  />
                </div>
            }
            </div>
        </div>;
    }
}

export default Home

    // < img src = { logo } className = "App-logo" alt = "logo" />
    // < p >
    // Edit < code > src / App.js</code > and save to reload.
    //             </p >
    // <a
    //     className="App-link"
    //     href="https://reactjs.org"
    //     target="_blank"
    //     rel="noopener noreferrer"
    // >
    //     Learn React
    // </a>

    // < img src = "swimming_top.png" alt = 'top image' />


    // < div className = "servicesContainer" >
    // {
    //     servicesData.map((item) => (
    //         <div className="servicesItem">
    //             {/* <div className="servicesItemText"> */}
    //             <p style={this.mystyle}>
    //                 We teach the basics of swimming to kids and make sure they have a strong foundation. We cover all the varieties like breathstroke, freestyle, backstroke etc.
    //             </p>
    //             {/* </div> */}
    //             <img src={item.img} alt='/' />
    //         </div>
    //     ))
    // }
    //         </div >




// servicesData.map((item) => (
//     <div className="servicesItem">
//         <div className="servicesItemText">
//             {item.text}
//         </div>
//         <img src={item.img} alt='/' />
//     </div>
// ))